/* You can add global styles to this file, and also import other style files */
@import 'global';
[tuiWrapper][data-appearance='textfield'] {
  box-shadow: none !important;
}
tui-input-time {
  border-radius: 4px !important;
}
.required::after {
  content: ' *';
  color: red;
}
.t-not-interactive-content > * {
  pointer-events: initial !important;
}
